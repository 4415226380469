.button {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 46px;
  right: -140px;

  background: #25d366;
  border-radius: 35px 0px 0px 35px;
  padding: 8px 25px 8px 20px;
  transition: all 0.5s ease-in-out;
  z-index: 5;

  &:hover {
    right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .button {
    bottom: 30px;
    right: -125px;

    &__visible {
      bottom: 188px;
      right: 0px;
    }
  }
}
